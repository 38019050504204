import httpClient from '@/services/http-client';

export async function getUsers() {
  const response = await httpClient.get('/api/adm/users/list');

  return response.data;
}

export async function getUser(userGuid) {
  const response = await httpClient.get(`/api/adm/users/details/${userGuid}`);

  return response.data;
}

export async function createUser(payload) {
  const response = await httpClient.post('/api/adm/users/item', payload);
  return response.data;
}

export async function saveProfile(updateUserGuid, payload) {
  const response = await httpClient.put(`/api/adm/users/details/${updateUserGuid}`, payload);
  return response.data;
}

export async function setDocumentSeries(userGuid, seriesIds, documentType) {
  const response = await httpClient.post(`/api/adm/users/document-series/${userGuid}`, {
    seriesIds,
    documentType,
  });

  return response.data;
}

export async function getDocumentSeries(userGuid, documentType) {
  const response = await httpClient.get(`/api/adm/users/document-series/${userGuid}`, {
    params: {
      documentType,
    },
  });

  return response.data;
}

export async function getInventoryAccess(userGuid) {
  const response = await httpClient.get(`/api/adm/users/inventory/${userGuid}`);

  return response.data;
}

export async function setInventoryAccess(userGuid, seriesIds) {
  const response = await httpClient.post(`/api/adm/users/inventory/${userGuid}`, seriesIds);

  return response.data;
}

export async function resetPassword(payload) {
  const response = await httpClient.put('/api/adm/users/reset-user-password', payload);

  return response.data;
}
